.prize-modal-common {
  background:
    linear-gradient(
      0deg,
      rgba(97, 19, 168, 0.48) 0%,
      rgba(97, 19, 168, 0.48) 100%
    ),
    linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.09) 0%,
      rgba(255, 255, 255, 0.09) 100%
    ),
    #0b2233;
}

.prize-modal-rare {
  background: linear-gradient(180deg, #370196 0%, #330185 100%, #263747);
}

.prize-modal-epic {
  background:
    linear-gradient(
      0deg,
      rgba(43, 6, 102, 0.48) 0%,
      rgba(43, 6, 102, 0.48) 100%
    ),
    linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.09) 0%,
      rgba(255, 255, 255, 0.09) 100%
    ),
    #0b2233;
}
